"use client";

import React, { useEffect, useState } from "react";
import axios from "@/utils/axios";
import { AxiosResponse } from "axios";
import { PRODUCT_FAQ } from "@/utils/endPoints";
import { USA_REGION_ID } from "@/utils/constants";
interface ProductIdProps {
  productId: number;
}
interface FAQItem {
  id: number;
  query: string;
  sorting: number;
  product_id: number;
  answer: string;
  region_id: number;
}
interface FAQResponse {
  data: {
    items: FAQItem[];
  };
}
export default function ProductFaq({ productId }: ProductIdProps) {
  const [faqs, setFaqs] = useState<FAQItem[]>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (item: any, index: number) => {
    window.dataLayer?.push({
      event: "FAQ_interaction",
      ecommerce: {
        items: item,
      },
    });
    setActiveIndex(activeIndex === index ? null : index);
  };

  const fetchfaqData = async () => {
    try {
      const response: AxiosResponse<FAQResponse> = await axios.get(
        `${PRODUCT_FAQ}?product_id=${productId}&region_id=${USA_REGION_ID}`
      );
      setFaqs(response?.data?.data?.items);
    } catch (error) {
      console.log("Error in fetching connect us Data ");
    }
  };

  useEffect(() => {
    fetchfaqData();
  }, []);

  return (
    <>
      {faqs.length > 0 && (
        <>
          <div className="Faq-wrapper">
            <div className="container">
              <div className="row faq-section">
                <div className="col-12 col-md-4">
                  <p className="mb-0 h4 text-white faq-heading d-none d-sm-block">
                    Frequently <br />
                    Asked <br /> Questions
                  </p>
                  <p className="h4 mb-3 text-white d-block d-sm-none faq-heading">
                    FAQ
                  </p>
                </div>
                <div className="col-12 col-md-8">
                  <div className="d-flex">
                    <div className="w-100">
                      <div
                        className="accordion bg-transparent"
                        id="accordionExample"
                      >
                        {faqs?.map((item: FAQItem, index: number) => (
                          <div
                            key={index}
                            className="accordion-item bg-transparent border-0"
                          >
                            <h3 className="accordion-header border-top faq-question">
                              <button
                                className={`accordion-button px-0 ${
                                  activeIndex === index ? "" : "collapsed"
                                } bg-transparent text-white`}
                                type="button"
                                id="faq-accordion-button"
                                onClick={() => toggleAccordion(item, index)}
                              >
                                {item?.query}
                              </button>
                            </h3>
                            <div
                              className={`accordion-collapse  ${
                                activeIndex === index ? "show" : ""
                              }`}
                              // data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faq-answer bg-transparent text-white px-0">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.answer ?? "",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div
                    className="accordion bg-transparent"
                    id="accordionExample"
                  >
                    {faqItems.map((item, index) => (
                      <div
                        className="accordion-item bg-transparent border-0"
                        key={index}
                      >
                        <h3 className="accordion-header border-top">
                          <button
                            className="accordion-button collapsed bg-transparent text-white faq-question"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded={index === 0 ? "true" : "false"}
                            aria-controls={`collapse${index}`}
                          >
                            {item.question}
                          </button>
                        </h3>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body bg-transparent text-white">
                            <div
                              className="text-white faq-answer mb-1"
                              dangerouslySetInnerHTML={{ __html: item.answer }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div> */}
                      <hr className="text-white mt-0 custom-opacity" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
